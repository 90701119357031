import { HighlightedRequest } from '@components/highlightedRequest/highlightedRequest';
import { StaticMap } from '@components/static-map/static-map';
import { useUiReadabilityEnhancements } from '@feature-flags/hooks/Storefront/useUiReadabilityEnhancements';
import { useIsVenue } from '@hooks/use-is-venue';
import { useAppSelector } from '@redux/hooks';
import { H3 } from '@xo-union/tk-ui-typography';
import { useAtomValue } from 'jotai';
import React, { type FC } from 'react';
import type { Decorated } from 'types/vendor';
import { travelAvailabilityAtom } from '../../jotai/storefront/organization';
import type { NavItemProps } from '../../pages/Storefront/containers/nav/types';
import { LocationHighlights } from './components/location-highlights/location-highlights';
import Styles from './location.scss';

interface LocationProps extends NavItemProps {
	forcedTitle?: null | string;
}

export const Location: FC<LocationProps> = ({ forcedTitle = null }) => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const isVenue = useIsVenue();
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();
	const title = isVenue ? 'Location' : 'Service area';
	const travelAvailability = useAtomValue(travelAvailabilityAtom);

	const isAddressPublic = vendor.address.isPublic;
	const hasHighlights = vendor.serviceArea || travelAvailability;

	if (!isAddressPublic && !travelAvailability && !isVenue) {
		return null;
	}

	return (
		<div className={Styles.container} id="location">
			<H3 className={Styles.title}>{forcedTitle ?? title}</H3>
			{isAddressPublic && <StaticMap address={vendor.address} />}
			<div className={Styles.bottomContent}>
				{!isUIReadabilityEnhancementsVariant && (isVenue || !hasHighlights) && (
					<HighlightedRequest
						messageText="Want to book a visit?"
						buttonText="Start a conversation"
						initiator="Location CTA"
						hideIfLead={true}
					/>
				)}
				{!isVenue && hasHighlights && (
					<LocationHighlights
						serviceArea={vendor.serviceArea}
						travelsUpTo={travelAvailability}
						isAddressPublic={isAddressPublic}
					/>
				)}
			</div>
		</div>
	);
};
