import { Highlight } from '@components/highlight/highlight';
import { useIsVenue } from '@hooks/use-is-venue';
import { useAppSelector } from '@redux/hooks';
import type { Decorated } from '@typings/vendor';
import { useAtomValue } from 'jotai';
import React, { useState } from 'react';
import { Link } from 'react-scroll';
import {
	detailedPricingStartingCostAtom,
	estimateUsuallySpendAtom,
} from '../../jotai/storefront/detailed-pricing';
import DetailsHelper from '../../pages/Storefront/containers/details/helpers';

import { EstimatedPricingDetailsModal } from '@components/shared-pricing/EstimatedPricingDetailsModal';
import { useUiReadabilityEnhancements } from '@feature-flags/hooks/Storefront/useUiReadabilityEnhancements';
import { useStorefrontEstimatedPricingExperiment } from '@hooks/use-storefront-estimated-pricing-experiment';
import { compose } from '@xo-union/react-css-modules';
import { DisplayButton } from '@xo-union/tk-ui-links';
import {
	photographyServicesAtom,
	travelAvailabilityAtom,
	videographyServicesAtom,
} from '../../jotai/storefront/organization';
import {
	formattedGuestCapacityAtom,
	startingCostAtom,
	venueEstimateUsuallySpendAtom,
} from '../../jotai/storefront/pricing';
import { availabilityDataAtom } from '../../pages/Storefront/components/AvailabilitySection/availability-atoms';
import Styles from './highlights-scannability.scss';

const CEREMONY_AREA_AMENITY_ID = 'd46b53a6-932e-4b77-8668-a2298c285a6d';
const RECEPTION_AREA_AMENITY_ID = '2ee9d5c0-4167-4450-9734-643c6bee601a';

const EstimatedPrice = ({
	classes,
	estimatedPrice,
	shouldShow,
}: {
	classes: React.ComponentProps<typeof Highlight>['classes'];
	estimatedPrice: string | null | undefined;
	shouldShow: boolean;
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	return (
		<>
			{shouldShow && (
				<>
					<EstimatedPricingDetailsModal
						isModalOpen={isModalOpen}
						closeCallback={() => setIsModalOpen(false)}
					/>
					<Highlight
						iconName="budgeter"
						classes={classes}
						highlightTitle={`${estimatedPrice} avg. price`}
						highlightText={
							<span>
								{'The Knot Estimate '}
								<DisplayButton
									type="button"
									onClick={() => setIsModalOpen(true)}
									color="tertiary"
									classes={compose({
										'tappable-area': Styles.estimatedPriceTappableArea,
									})}
								>
									See details
								</DisplayButton>
							</span>
						}
					/>
				</>
			)}
		</>
	);
};

export const HighlightsScannability = () => {
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();
	const isVenue = useIsVenue();
	const availabilityData = useAtomValue(availabilityDataAtom);

	const serviceAreas = vendor.serviceArea;
	const travelAvailability = useAtomValue(travelAvailabilityAtom);
	const videographyServices = useAtomValue(videographyServicesAtom);
	const photographyServices = useAtomValue(photographyServicesAtom);
	const guestCapacity = useAtomValue(formattedGuestCapacityAtom);

	const startingCost = useAtomValue(
		isVenue ? startingCostAtom : detailedPricingStartingCostAtom,
	);

	const hasCeremonyAndReceptionArea =
		vendor.amenities.filter((amenity: Settings.VenueAmenity) => {
			if (
				amenity.id === CEREMONY_AREA_AMENITY_ID ||
				amenity.id === RECEPTION_AREA_AMENITY_ID
			) {
				return amenity;
			}
		}).length === 2;

	const currentYear = new Date().getFullYear();

	const highlightClasses = {
		title: Styles.highlightsTitle,
		text: Styles.highlightsText,
		container: Styles.highlightsContainer,
		subContainer: Styles.highlightsTextContainer,
	};

	const hasDetails = DetailsHelper.hasDetails(vendor);
	const isInEstimatedPricing = useStorefrontEstimatedPricingExperiment();
	const estimatedPrice = useAtomValue(venueEstimateUsuallySpendAtom);
	const estimatedPriceNonVenue = useAtomValue(estimateUsuallySpendAtom);
	const shouldShowVenueEstimatedPricing = Boolean(
		isInEstimatedPricing && isVenue && !startingCost && estimatedPrice,
	);
	const shouldShowNonVenueEstimatedPricing = Boolean(
		isInEstimatedPricing && !isVenue && !startingCost && estimatedPriceNonVenue,
	);

	const hasHighlights =
		startingCost ||
		serviceAreas ||
		guestCapacity ||
		hasCeremonyAndReceptionArea ||
		videographyServices ||
		photographyServices ||
		availabilityData ||
		shouldShowVenueEstimatedPricing ||
		shouldShowNonVenueEstimatedPricing;

	if (hasHighlights) {
		return (
			<div className={Styles.container}>
				<EstimatedPrice
					classes={highlightClasses}
					estimatedPrice={estimatedPrice}
					shouldShow={shouldShowVenueEstimatedPricing}
				/>
				<EstimatedPrice
					classes={highlightClasses}
					estimatedPrice={estimatedPriceNonVenue}
					shouldShow={shouldShowNonVenueEstimatedPricing}
				/>
				{startingCost && (
					<Highlight
						iconName="budgeter"
						classes={highlightClasses}
						highlightTitle={`${startingCost} starting price`}
						highlightText={
							<span>
								<Link
									href={'#navPricing'}
									to="navPricing"
									smooth
									spy
									isDynamic
									offset={-49}
								>
									See details
								</Link>
							</span>
						}
					/>
				)}
				{!isUIReadabilityEnhancementsVariant && serviceAreas && (
					<Highlight
						iconName="address"
						classes={highlightClasses}
						highlightTitle={serviceAreas}
						highlightText={
							travelAvailability ? (
								<span>
									{`Travels up to ${travelAvailability} miles `}
									<Link
										href={'#location'}
										to="location"
										smooth
										spy
										isDynamic
										offset={-49}
									>
										See details
									</Link>
								</span>
							) : (
								'Service area'
							)
						}
					/>
				)}
				{guestCapacity && (
					<Highlight
						iconName="guest_couple"
						classes={highlightClasses}
						highlightTitle={guestCapacity}
					/>
				)}

				{hasCeremonyAndReceptionArea && (
					<Highlight
						iconName="category-cer"
						classes={highlightClasses}
						highlightTitle="Holds ceremonies and receptions"
					/>
				)}
				{videographyServices && (
					<Highlight
						iconName="category-vid"
						classes={highlightClasses}
						highlightTitle="Videography services available"
						highlightText={
							hasDetails && (
								<Link
									href={'#navDetails'}
									to="navDetails"
									smooth
									spy
									isDynamic
									offset={-49}
								>
									See services
								</Link>
							)
						}
					/>
				)}
				{photographyServices && (
					<Highlight
						iconName="category-wph"
						classes={highlightClasses}
						highlightTitle="Photography services available"
						highlightText={
							hasDetails && (
								<Link
									href={'#navDetails'}
									to="navDetails"
									smooth
									spy
									isDynamic
									offset={-49}
								>
									See services
								</Link>
							)
						}
					/>
				)}
				{availabilityData && (
					<Highlight
						iconName="date"
						classes={highlightClasses}
						highlightTitle={`Booking in ${currentYear}, ${currentYear + 1}`}
						highlightText={
							<Link
								href={'#navAvailability'}
								to="navAvailability"
								smooth
								spy
								isDynamic
								offset={-49}
							>
								See available dates
							</Link>
						}
					/>
				)}
				{isUIReadabilityEnhancementsVariant && serviceAreas && (
					<Highlight
						iconName="address"
						classes={highlightClasses}
						highlightTitle={serviceAreas}
						highlightText={
							travelAvailability ? (
								<span>
									{`Travels up to ${travelAvailability} miles `}
									<Link
										href={'#location'}
										to="location"
										smooth
										spy
										isDynamic
										offset={-49}
									>
										See details
									</Link>
								</span>
							) : (
								'Service area'
							)
						}
					/>
				)}
			</div>
		);
	}

	return null;
};
